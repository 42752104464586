import React from "react";
import { navigate } from "gatsby";
import { getMobileOperatingSystem } from "../lib/shared";


export default () => {
  React.useEffect(() => {
    const platform = getMobileOperatingSystem()

    if (platform === 'iOS') {
      navigate('https://testflight.apple.com/join/4JwQftiD', {replace: true})
    }
    else if (platform === 'Android') {
      //navigate('https://play.google.com/apps/testing/ch.ephoria.karma', {replace: true})
      navigate('https://play.google.com/store/apps/details?id=ch.ephoria.karma', {replace: true})
    }
    else  navigate('/', {replace: true})
  }, [])

  return null
}
